<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Vui lòng chọn" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>Giao thức phát sóng trực tiếp "UniLive"</h2>
		<p>Giao thức phát sóng trực tiếp UniLive là Pix Technology PTE. LTD.
			Một thỏa thuận điện tử có hiệu lực pháp lý được thỏa thuận giữa LTD. (sau đây gọi là "chúng tôi") và bạn (bạn là
			thể
			nhân, pháp nhân hoặc tổ chức khác) điều chỉnh quyền và nghĩa vụ của các bên, sau đây gọi là "Thỏa thuận này". Bằng
			cách đánh dấu "Tôi đồng ý" hoặc nhấp vào nút "Tôi đã đọc và tuân thủ Thỏa thuận", bạn có nghĩa là bạn đã đọc kỹ,
			hiểu đầy đủ và hoàn toàn chấp nhận tất cả các điều khoản của Thỏa thuận này mà không cần bảo lưu.
		</p>
		<h3>Quy tắc cơ bản của Uni Live đối với phát trực tiếp</h3>
		<p>
			Nội dung phát trực tiếp
		</p>
		<p>
			1. Thông tin bất hợp pháp và có hại  
			Cấm lan truyền nội dung liên quan đến các vấn đề chính trị nhạy cảm, ly khai dân tộc hoặc cực đoan tôn giáo.  
			Cấm hiển thị bạo lực, máu me, kinh dị, cờ bạc, ma túy hoặc bất kỳ hành vi bất hợp pháp nào.  
			Cấm tuyên truyền mê tín dị đoan (ví dụ: bói toán, tiên đoán), cần tránh quảng bá quá mức.  
		</p>
		<p>
			2. Nội dung khiêu dâm và phản cảm  
			Cấm khỏa thân, gợi dục, biểu diễn phản cảm hoặc bất kỳ nội dung khiêu dâm nào.  
			Trang phục không được quá hở hang (ví dụ: hở ngực, lộ mông, đồ xuyên thấu), nếu vi phạm có thể bị gián đoạn phát trực tiếp.  
			Cấm sử dụng ngôn ngữ hoặc hành vi mang tính khiêu khích liên quan đến chủ đề tình dục.  
		</p>
		<p>
			3. Xâm phạm quyền riêng tư của người khác  
			Cấm tiết lộ thông tin cá nhân của người khác khi chưa được phép (ví dụ: tên, địa chỉ).  
			Cấm bắt nạt trên mạng, tấn công ác ý hoặc phỉ báng người khác.  
		</p>
		<p>
			4. Hành vi lừa đảo và thông tin sai lệch  
			Cấm lan truyền tin đồn, quảng cáo sai sự thật hoặc thông tin chưa được xác minh (ví dụ: phóng đại công dụng của sản phẩm sức khỏe).  
			Cấm lừa đảo, kinh doanh đa cấp hoặc dụ dỗ người dùng giao dịch cá nhân.  
		</p>
		<p>
			5. Nội dung liên quan đến trẻ vị thành niên  
			Phải bảo vệ trẻ vị thành niên nghiêm ngặt, trẻ em không được tham gia phát trực tiếp.  
			Cấm hiển thị nội dung có thể gây hại đến sức khỏe thể chất hoặc tinh thần của trẻ vị thành niên.  
		</p>

		<h3>Quy tắc cơ bản của Uni Live đối với việc tải video lên</h3>
		<p>
			Hướng dẫn nội dung (tiêu chuẩn cộng đồng)
		</p>
		<p>
			Cấm nội dung bạo lực: Không được tải lên video có hình ảnh bạo lực, gây thương tích, ngược đãi hoặc liên quan đến khủng bố, bao gồm cả cảnh bạo lực giả lập.  
		</p>
		<p>
			Cấm nội dung khiêu dâm hoặc nhạy cảm: Không được tải lên nội dung khỏa thân, gợi dục, khiêu dâm hoặc nội dung dành cho người lớn dưới mọi hình thức.  
		</p>

		<p>
			Cấm phát ngôn thù địch: Không được đăng tải nội dung phân biệt đối xử, công kích hoặc kích động thù hận đối với bất kỳ nhóm nào dựa trên chủng tộc, tôn giáo, giới tính, xu hướng tình dục hoặc quốc tịch.  
		</p>
		<p>
			Cấm bắt nạt và quấy rối: Không được tải lên nội dung sỉ nhục, đe dọa hoặc quấy rối người khác, bao gồm cả hành vi không phù hợp đối với trẻ em.  
		</p>
		<p>
			Cấm hành vi nguy hiểm: Không được hiển thị thử thách nguy hiểm, trò đùa quá trớn hoặc hoạt động bất hợp pháp (ví dụ: sử dụng ma túy, vũ khí) có thể gây thương tích.  
		</p>

		<h3>Nguyên tắc đầu tiên</h3>
		<p>Nền tảng UniLive bao gồm, nhưng không giới hạn, Pix Technology PTE. LTD. và các chi nhánh của Pix Technology PTE. LTD.
			LT. Tự có nền tảng phát sóng trực tiếp. Bạn áp dụng để trở thành nhà cung cấp dịch vụ phát trực tiếp của chúng tôi
			(hoặc "bên phát trực tiếp") trên nền tảng UniLive do chúng tôi vận hành hợp pháp (sau đây gọi là "nền tảng") để
			cung
			cấp dịch vụ phát trực tiếp nội dung video trực tuyến cho người dùng nền tảng của chúng tôi, phù hợp với các yêu
			cầu
			và quy tắc đăng ký của chúng tôi, và bạn sẽ được coi là trong thời gian cung cấp dịch vụ trên nền tảng của chúng
			tôi. Chúng tôi không xem xét trước nội dung video được tải lên, do bạn tham gia, chỉnh sửa, chế tác, cũng không
			chủ
			động tiến hành chỉnh sửa, sắp xếp, sửa đổi, gia công các video này.
		</p>
		<p>
			Trước khi ký Thỏa thuận này, bạn đã hiểu đầy đủ các quy tắc và yêu cầu của chúng tôi và có đủ điều kiện và năng
			lực và đủ điều kiện để thực hiện các nhiệm vụ và nghĩa vụ của bên phát sóng trực tiếp đã thỏa thuận trong Thỏa
			thuận này. Thỏa thuận này cấu thành một nghĩa vụ pháp lý có hiệu lực, ràng buộc và có thể thực thi đối với bạn và
			bạn đã hiểu rõ và không nghi ngờ gì về tất cả các điều khoản và định nghĩa của Thỏa thuận này.
		</p>
		<p>3 Bạn cam kết và tuyên bố rằng việc cung cấp dịch vụ cho chúng tôi phù hợp với các quy định của pháp luật địa
			phương và không thực hiện các hành vi vi phạm khác của Trung Quốc và pháp luật địa phương nhân danh Thỏa thuận
			này.</p>
		<p>4. Bạn và chúng tôi không cấu thành bất kỳ mối quan hệ lao động, lao động, lao động nào ở cấp độ pháp lý về lao
			động, chúng tôi không cần phải trả tiền bảo hiểm xã hội và phúc lợi cho bạn.</p>
		<p>5. bạn không được tham gia vào bất kỳ hành vi nào liên quan đến phát sóng trực tiếp trên nền tảng cạnh tranh của
			bên thứ ba mà không có sự đồng ý trước bằng văn bản của chúng tôi.</p>
		<p>Bạn đã xác nhận trước khi đăng ký để trở thành một neo UniLive hoặc thực hiện các dịch vụ phát sóng trực tiếp
			trên
			nền tảng UniLive rằng bạn không ký hoặc tồn tại thỏa thuận phát sóng trực tiếp độc quyền với bất kỳ nền tảng bên
			thứ ba nào.</p>
		<p>Quyền phát sóng trực tiếp, tải lên, xuất bản hoặc truyền tải nội dung của bạn trong thời gian hợp tác giữa các
			bên
			áp dụng cho các điều khoản trong Thỏa thuận dịch vụ người dùng UniLive.</p>
		<h3>Quyền và nghĩa vụ của chúng tôi</h3>
		<p>Chúng tôi có quyền thiết lập hệ thống hoạt động của Nền tảng và các quy tắc quản lý đối với bên phát sóng trực
			tiếp
			như một phần của Thỏa thuận này, có quyền quản lý và giám sát bạn, có quyền điều chỉnh hoặc thay đổi các quy tắc
			tương ứng theo tình hình hoạt động mà bạn hiểu và đồng ý.</p>
		<p>
			2. Bên chúng tôi có quyền tiến hành khảo sát, đánh giá bên bạn để xác lập (hủy bỏ) khen thưởng hoặc xử phạt bên
			bạn, hạng mục khảo sát cụ thể và tiêu chuẩn do bên chúng tôi đặt ra, không cần phải xin thêm sự đồng ý của bên
			bạn. Nếu chúng tôi yêu cầu ký kết thỏa thuận hợp tác phát sóng trực tiếp chính thức với bạn, bạn không được từ
			chối hoặc từ chối ký kết dưới hình thức khác [nếu không bạn phải trả cho chúng tôi một lần tiền vi phạm hợp đồng
			[50.000] (viết hoa: 5.000.000 đồng).
		</p>
		<p>
			Chúng tôi có quyền đưa ra các đề xuất và ý kiến cải tiến đối với Dịch vụ phát trực tiếp của bạn và bạn phải thực
			hiện các chỉnh sửa tương ứng trong vòng [3] ngày làm việc sau khi nhận được đề xuất và ý kiến của chúng tôi, nếu
			không chúng tôi có quyền hạn chế, đóng cửa, tái chế hoặc chấm dứt việc sử dụng Phòng phát trực tiếp UniLive của
			bạn và khấu trừ, nếu có, phí dịch vụ phải trả cho bạn có thể gây ra tổn thất nhất định cho bạn, tổn thất này do
			bạn tự chịu và chúng tôi không chịu trách nhiệm.
		</p>
		<p>4, Chúng tôi có quyền quyết định cuối cùng đối với việc phát sóng trực tiếp của bên anh.</p>
		<p>5,Chúng tôi có quyền sử dụng tên của bạn (bao gồm nhưng không giới hạn ở tên thật, bút danh, tên mạng, tên đã
			từng sử
			dụng và bất kỳ ký hiệu văn bản nào đại diện cho danh tính của bạn), chân dung (bao gồm nhưng không giới hạn ở chân
			dung người thật và chân dung hoạt hình, v.v.) để quảng bá trên trang web của chúng tôi.</p>
		<p>6, chúng tôi chịu trách nhiệm cung cấp hỗ trợ dịch vụ kỹ thuật nền tảng, đồng thời chịu trách nhiệm thanh toán
			chi phí dịch vụ nền tảng (nếu có).</p>
		<h3>Quyền và nghĩa vụ của bạn</h3>
		<p>
			Bạn nên sử dụng thông tin nhận dạng thực và dữ liệu cá nhân để không đăng ký và xác thực thông tin nhận dạng cư
			dân giả mạo, giả mạo, thông tin đăng ký doanh nghiệp, thông tin mã tổ chức. Nếu có bất kỳ thay đổi nào trong dữ
			liệu cá nhân của bạn, nó sẽ được cập nhật kịp thời. Chúng tôi nghiêm cấm phát sóng trực tiếp của trẻ vị thành
			niên, và nếu người giám hộ hợp pháp muốn trẻ vị thành niên cung cấp các dịch vụ phát sóng trực tuyến và phát sóng
			trực tiếp theo thỏa thuận này, người giám hộ hợp pháp phải xác định xem nội dung của các dịch vụ đó có phù hợp với
			trẻ vị thành niên hay không và người giám hộ hợp pháp phải chịu mọi hậu quả do đó. Bạn cam kết sẽ không làm tổn
			hại đến lợi ích hợp pháp của bất kỳ bên thứ ba nào bằng cách thực hiện Thỏa thuận này, và việc bạn chấp nhận và
			thực hiện Thỏa thuận này không vi phạm bất kỳ tài liệu pháp lý nào ràng buộc bạn và sẽ không khiến chúng tôi chịu
			trách nhiệm đối với bất kỳ bên thứ ba nào.
		</p>
		<p>2, bạn tự chịu trách nhiệm về mạng lưới, hỗ trợ video và giọng nói cần thiết để phát sóng trực tiếp, và đảm bảo
			hình ảnh trực tiếp rõ ràng, chất lượng giọng nói rõ ràng và ổn định.</p>
		<p>3. Bên bạn cam kết, nguyện ý tuân theo trình tự xin phòng phát sóng trực tiếp do nền tảng thiết lập, đệ trình tài
			liệu xin phép cần thiết và tự nguyện nộp tiền ký quỹ tương ứng.</p>
		<p>4. Bên anh cam kết phòng phát sóng trực tiếp phải dùng để phát sóng trực tiếp, không được dùng cho bất cứ hoạt
			động nào khác không có tính chất phát sóng trực tiếp. Khi bạn thực hiện giao hàng trực tiếp, bạn và nhà cung cấp
			của bạn phải tuân thủ các quy định của pháp luật Trung Quốc và thực hiện các quy định liên quan của Thỏa thuận
			dịch vụ thương gia do chúng tôi ban hành.</p>
		<p>
			Tất cả các thông tin/thông tin/ngôn luận/nội dung mà bạn phát sóng trực tiếp theo Thỏa thuận này và/hoặc công bố
			trên Nền tảng này sẽ không chứa bất kỳ nội dung nào vi phạm pháp luật, quy định và quy định liên quan của Cộng hòa
			Nhân dân Trung Hoa, bao gồm nhưng không giới hạn ở việc gây nguy hiểm cho an ninh quốc gia, khiêu dâm, giả mạo,
			bất hợp pháp, phỉ báng (bao gồm cả phỉ báng thương mại), đe dọa hoặc quấy rối bất hợp pháp, xâm phạm quyền sở hữu
			trí tuệ của người khác, quyền cá nhân, bí mật thương mại hoặc các quyền hợp pháp khác và nội dung trái với trật tự
			hoặc liên kết đến những nội dung này.
		</p>
		<p>
			6. Bạn cam kết tích cực bảo vệ hình ảnh của chúng tôi và Nền tảng của chúng tôi, bạn sẽ không có hành vi gây tổn
			hại đến hình ảnh hoặc lợi ích của chúng tôi và/hoặc Nền tảng của chúng tôi, trong thời hạn của Thỏa thuận này và
			sau khi chấm dứt Thỏa thuận, bạn sẽ không ám chỉ hoặc công bố ngôn luận không có lợi cho chúng tôi và/hoặc Nền
			tảng của chúng tôi thông qua bất kỳ kênh nào (bao gồm nhưng không giới hạn ở Trang web, blog, Wechat, Nhóm chat
			QQ, Tụ họp người chơi......).
		</p>
		<p>
			7. Nếu không có sự đồng ý bằng văn bản của chúng tôi, bạn không được đề cập đến thông tin liên quan đến nền tảng
			cạnh tranh của bên thứ ba dưới bất kỳ hình thức nào (bao gồm nhưng không giới hạn ở văn bản, phát sóng bằng miệng,
			video v. v.), không được hướng dẫn hoặc cung cấp bất kỳ thông tin hoặc tiện lợi nào cho người dùng hiện tại của
			nền tảng của chúng tôi, các bên phát sóng trực tiếp khác và nhân viên của chúng tôi vào nền tảng cạnh tranh của
			bên thứ ba khác, bao gồm nhưng không giới hạn ở việc cung cấp hỗ trợ liên lạc, tiến hành công tác thuyết
			phục......
		</p>
		<p>
			1. Lấy bạn làm tiền đề cung cấp dịch vụ truyền hình trực tiếp cho người sử dụng nền tảng, người sử dụng có thể
			tiến hành tiêu dùng tặng quà ảo cho bạn, bạn có thể căn cứ vào yêu cầu thanh toán và quy tắc của bên chúng tôi xin
			thanh toán lợi nhuận liên quan (nếu có), bên chúng tôi thu phí dịch vụ kỹ thuật tỷ lệ nhất định và khấu trừ tiền
			thuế tương ứng sau đó thanh toán cho bên bạn. Đối với việc tiêu thụ quà tặng ảo có được bằng các phương tiện bất
			thường, chúng tôi có quyền tiến hành phán đoán và xử lý độc lập.
		</p>
		<p>
			Chi phí dịch vụ bạn nhận được phải nộp thuế theo luật pháp và quy định của quốc gia, chúng tôi sẽ thanh toán chi
			phí
			dịch vụ bạn nhận được vào tài khoản ngân hàng bạn điền trong Trung tâm người dùng, bạn có thể truy vấn thông tin
			liên quan trong hồ sơ thu nhập sau khi đăng nhập vào Nền tảng của chúng tôi (dữ liệu thanh toán là dữ liệu bao gồm
			thuế).</p>
		<p>
			Nếu bạn là thể nhân và được đăng ký trên Nền tảng của chúng tôi và được chứng nhận cá nhân, chúng tôi có quyền
			thanh toán phí dịch vụ mà bạn nhận được vào tài khoản ngân hàng cá nhân mà bạn điền vào Trung tâm Người dùng; Nếu
			bạn là pháp nhân hoặc tổ chức khác và được đăng ký với Nền tảng của chúng tôi và được chứng nhận bởi tổ chức,
			chúng tôi có quyền thanh toán phí dịch vụ mà bạn nhận được vào tài khoản tổ chức mà bạn điền vào trang chứng nhận
			của tổ chức, nhưng bạn phải cung cấp cho chúng tôi hóa đơn VAT có giá trị tương đương (tên hóa đơn là phí dịch vụ
			trực tiếp) trong vòng 5 ngày làm việc trước khi chúng tôi thanh toán, do sự chậm trễ trong việc cung cấp hóa đơn
			của bạn không cấu thành vi phạm của chúng tôi. Việc chúng tôi thanh toán Dịch vụ theo tài khoản mà bạn đã điền
			được coi là chúng tôi đã hoàn thành nghĩa vụ thanh toán theo thỏa thuận này. Nếu bạn là pháp nhân hoặc tổ chức
			khác, chi phí phát sinh từ việc quản lý và vận hành tài khoản và phòng phát sóng trực tiếp của nhân viên hoặc phát
			thanh viên trực thuộc của bạn do bạn tự giải quyết với nhân viên của bạn hoặc phát thanh viên trực thuộc. Nếu bạn
			gây ra tranh chấp, kiện tụng hoặc bồi thường thiệt hại cho chúng tôi do việc giải quyết chi phí đó (bao gồm, nhưng
			không giới hạn, số tiền mà chúng tôi ứng trước cho nhân viên hoặc người dẫn chương trình của bạn khi bạn nợ tiền
			lương của họ), chúng tôi có quyền khấu trừ chi phí dịch vụ phải trả trước và không đủ số tiền mà chúng tôi có
			quyền thu hồi từ bạn.
		</p>
		<p>Bạn đảm bảo rằng việc điền thông tin tài khoản là chính xác, trung thực và hợp lệ, nếu bất kỳ lỗi thanh toán nào
			của
			chúng tôi do thông tin tài khoản gây ra, bạn sẽ tự chịu trách nhiệm. Đồng thời, nếu bạn cần thay đổi thông tin tài
			khoản, bạn cần thông báo cho chúng tôi kịp thời bằng văn bản, thông tin tài khoản mới sẽ có hiệu lực vào tháng
			thanh
			toán tiếp theo sau khi bạn nộp đơn và được chúng tôi xem xét thông qua.</p>
		<h3>Hệ thống bảo mật</h3>
		<p>Bạn nên tuân thủ nghiêm ngặt chế độ bảo mật của chúng tôi và cam kết giữ bí mật thương mại của chúng tôi vô thời
			hạn. Nếu bạn sử dụng hoặc tiết lộ bí mật thương mại và thông tin của chúng tôi vi phạm thỏa thuận khiến chúng tôi
			phải chịu bất kỳ tổn thất nào về danh tiếng, danh tiếng hoặc tài chính, trực tiếp hoặc gián tiếp, bạn sẽ bồi
			thường
			cho chúng tôi 100.000 NDT.
			Tiền vi phạm hợp đồng không đủ để bù đắp tổn thất của chúng tôi, bạn cũng phải bồi thường tổn thất của chúng tôi.
		</p>
		<p>
			Bí mật thương mại là tất cả các thông tin mà chúng tôi cung cấp, có tính thực tiễn và không được biết đến, bao gồm
			(nhưng không giới hạn): thông tin kỹ thuật, thông tin kinh doanh và thông tin và tài liệu liên quan đến quản lý
			hành chính của chúng tôi (bao gồm nội dung của Thỏa thuận này và các thỏa thuận liên quan), số tiền và phương thức
			thanh toán chi phí dịch vụ mà bạn nhận được từ chúng tôi, tiêu chuẩn, phương thức phân bổ quyền, phương thức ủy
			quyền, tên khách hàng, danh sách các nhà phát sóng trực tiếp khác, phương thức liên lạc, chi phí dịch vụ, danh
			sách nhân viên của chúng tôi, v.v. không được công chúng biết đến.
		</p>
		<p>Bạn phải tuân thủ nghiêm ngặt Thỏa thuận này và không được giữ bí mật thương mại của chúng tôi mà không có sự cho
			phép hoặc đồng ý bằng văn bản của chúng tôi:</p>
		<ul>
			<li>(1) phổ biến, tiết lộ dưới bất kỳ hình thức nào cho bên thứ ba hoặc công chúng không cụ thể;</li>
			<li>(2) Sử dụng bí mật thương mại của chúng tôi cho các mục đích không phải là mục đích của Thỏa thuận này.</li>
		</ul>
		<p>Khi chấm dứt Thỏa thuận này, bạn sẽ trả lại cho chúng tôi tất cả các bí mật thương mại của chúng tôi hoặc, dưới
			sự
			giám sát của chúng tôi, tiêu hủy toàn bộ tài liệu ghi lại bí mật thương mại của chúng tôi.</p>
		<p>5 Các quy định của Điều khoản này vẫn có hiệu lực sau khi chấm dứt Thỏa thuận này.</p>
		<h3>Thay đổi, hủy bỏ, chấm dứt thỏa thuận</h3>
		<p>
			Chúng tôi có quyền thay đổi, đình chỉ, chấm dứt Thỏa thuận này khi cần thiết và thông báo trên trang có liên quan
			rằng Thỏa thuận thay đổi sẽ thay thế thỏa thuận ban đầu một khi được công bố trên trang có liên quan. Khi các điều
			khoản của Thỏa thuận này thay đổi, nếu bạn tiếp tục cung cấp các dịch vụ phát trực tiếp như phát sóng trực tiếp
			cho người dùng Nền tảng của chúng tôi, bạn được coi là đã biết và chấp nhận Thỏa thuận thay đổi. Nếu bạn không
			đồng ý với bất kỳ thay đổi nào mà chúng tôi thực hiện đối với Thỏa thuận này, bạn phải thông báo cho chúng tôi
			ngay lập tức bằng văn bản và ngừng bất kỳ dịch vụ phát sóng trực tiếp nào được thực hiện trên Nền tảng của chúng
			tôi.
		</p>
		<p>2 Các bên có thể chấm dứt thỏa thuận bằng sự đồng thuận về việc dỡ bỏ thỏa thuận này.</p>
		<p>3 Chúng tôi có thể hủy hợp đồng này ngay lập tức mà không cần thông báo trước nếu bạn có một trong các trường hợp
			sau:</p>
		<ul>
			<li>(1) khi chúng tôi phát hiện bạn vi phạm các tuyên bố và cam kết của bạn đối với Thỏa thuận này;</li>
			<li>(2) gây thiệt hại đáng kể cho lợi ích của chúng tôi, trực tiếp hoặc gián tiếp, do hành động của bạn;</li>
			<li>(3) Vi phạm pháp luật quốc gia;</li>
			<li>(4) Vi phạm các nghĩa vụ khác theo Thỏa thuận này;</li>
			<li>(5) thực hiện Thỏa thuận này theo cách tiêu cực, không hành động, v.v. không phù hợp với yêu cầu của chúng tôi
				(ngay cả khi nó không cấu thành vi phạm) mà không được sửa chữa trong vòng 10 ngày sau khi chúng tôi thông báo;
			</li>
			<li>(6) Chúng tôi tin rằng bạn không phù hợp để thực hiện các dịch vụ theo Thỏa thuận này do sự xuất hiện của các
				trường hợp bất thường và các trường hợp bất thường vẫn chưa được loại bỏ trong vòng 10 ngày sau khi chúng tôi
				thông báo;</li>
			<li>(7) Do điều chỉnh nghiệp vụ của chúng tôi, không còn tiến hành nghiệp vụ dịch vụ truyền hình trực tiếp nữa.
			</li>
		</ul>
		<p>4, do việc hủy bỏ và chấm dứt thỏa thuận gây ra bởi khoản 1 và 2 Điều 6 của Thỏa thuận này, chúng tôi đã thỏa
			thuận với bạn về chi phí dịch vụ thanh toán theo Điều 4 của Thỏa thuận này và theo chính sách thời gian thực của
			Nền tảng của chúng tôi.</p>
		<p>5 Do việc hủy bỏ, chấm dứt thỏa thuận gây ra bởi Điều 6 khoản 3 của Thỏa thuận này, chúng tôi có quyền khấu trừ
			toàn bộ chi phí dịch vụ chưa được giải quyết trong tài khoản của bạn và yêu cầu bạn chịu trách nhiệm về vi phạm
			như đã thỏa thuận.</p>
		<h3>Trách nhiệm vi phạm Điều 7</h3>
		<p>Bất kỳ hậu quả nào của hành động, khiếu nại và/hoặc làm tổn hại danh tiếng của chúng tôi do tuyên bố sai sự thật
			hoặc vi phạm cam kết tuyên bố của bạn, bạn sẽ phải chịu toàn bộ chi phí, tổn thất và bồi thường trực tiếp và gián
			tiếp phát sinh từ chúng tôi, bao gồm cả chi phí liên quan và phí luật sư mà chúng tôi phải trả cho vụ kiện.</p>
		<p>
			Trừ khi có thỏa thuận khác trong Thỏa thuận này, khi bạn vi phạm bất kỳ điều khoản nào trong Thỏa thuận này hoặc
			Dịch vụ do bạn cung cấp không phù hợp với yêu cầu của chúng tôi, chúng tôi có quyền đơn phương áp dụng các biện
			pháp hạn chế hoặc trừng phạt tương ứng, bao gồm nhưng không giới hạn: hạn chế, đóng cửa, tái chế hoặc chấm dứt
			việc sử dụng UniLive Direct của bạn, hạn chế hoặc ngừng một dịch vụ riêng lẻ (ví dụ: phát trực tiếp video) và
			quyết định có tiếp tục sử dụng theo tình hình thực tế hay không, khấu trừ chi phí dịch vụ chưa được giải quyết
			trong tài khoản của bạn.
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/LiveAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/LiveAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/LiveAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/LiveAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/LiveAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/LiveAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/LiveAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/LiveAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/LiveAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/LiveAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/LiveAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/LiveAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/LiveAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/LiveAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>